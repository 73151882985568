import React from 'react';
import styles from "./Footer.module.css";
import { NavLink } from 'react-router-dom';
import logo from "../../assets/media/company/nvig-logo-horizontal.png";
import qrChat from "../../assets/media/company/qr-chat.png";
import qrFollow from "../../assets/media/company/qr-follow.png";

const Footer: React.FC = () => {
    return (
        <footer className={`${styles.footerContainer} text-main relative z-[100] bg-secondary-dark border-t-0.5 border-main border-opacity-40 p-6 text-left flex flex-col gap-11`}>
            <NavLink to="/"><img className="h-8" src={logo} alt="company logo" /></NavLink>
            <ul className='flex flex-col sm:flex-row gap-10 justify-between'>
                {/* <li className='flex-grow-[2]'>
                    <p>集团相关链接</p>
                    <ul>
                        <li className='hover:underline underline-offset-4'><a href='http://newvfo.com'>新愿景家族办公室</a></li>
                        <li className='hover:underline underline-offset-4'><a href='https://nvre.co.uk'>新愿景房地产</a></li>
                    </ul>
                </li> */}
                <li className='text-secondary-light'>
                    <p>联系我们</p>
                    <ul>
                        <li>邮箱：<a href='mailto:info@newvgroup.com'>info@newvgroup.com</a></li>
                        <li className='text-sm'>电话：+44 (0)20 3741 8080</li>
                        <li>表格：<NavLink to="/contact-us">跳转填写表格</NavLink></li>
                    </ul>
                </li>
                <li>
                    <div className='flex flex-row'>
                        <div className={styles.qrContainer}>
                            <img src={qrFollow} alt="QR code to follow us on wechat, or search:" />
                            <p>Follow us</p>
                        </div>
                        <div className={styles.qrContainer}>
                            <img src={qrChat} alt="QR code to chat with us on wechat, or search:" />
                            <p>Chat with us</p>
                        </div>
                    </div>
                </li>
            </ul>
            <div className='opacity-50 text-xs flex flex-col sm:flex-row gap-2 justify-between text-secondary-light'>
                <p>New Vision International Group Limited 新愿景国际集团有限公司，注册于英格兰及威尔士，公司编号为13029696。</p>
                <p>&copy;{new Date().getFullYear()} 新愿景集团 版权所有</p>
            </div>
        </footer>
    );
};

export default Footer;
